import axios, { AxiosInstance, AxiosResponse } from "axios";
import { injectBearerToken, requestReject } from "@/api/axios";
import { problemInterceptor } from "@/api/portal/taskProblemInterceptor";
import { Document, ITasks, Link, PaginatedResponse } from "@/models/document";
import QueryString from "qs";
import { Account } from "@/interfaces/Account";
import { BusinessUnit } from "@/models/branch";

export const taskReadApiClient = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_TASK_READ_API_URL,
    timeout: process.env.VUE_APP_API_REQUEST_TIMEOUT as number | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  axiosInstance.interceptors.response.use((r) => r, problemInterceptor);
  return axiosInstance;
};

export const taskReadApi = {
  accounts(): Promise<AxiosResponse<Account[]>> {
    return taskReadApiClient().get("/tasks/accounts");
  },
  businessUnits(): Promise<AxiosResponse<BusinessUnit[]>> {
    return taskReadApiClient().get("/tasks/businessunits");
  },
  fromLink(link: Link): Promise<AxiosResponse<PaginatedResponse<ITasks>>> {
    return taskReadApiClient().get(
      link.href.substr(link.href.indexOf("/tasks")),
      {
        headers: { Accept: "application/hal+json" },
        transformResponse: [
          (data: string) => PaginatedResponse.fromResponse(JSON.parse(data)),
        ],
      }
    );
  },
  tasks(params = {}): Promise<AxiosResponse<PaginatedResponse<ITasks>>> {
    return taskReadApiClient().get(`/tasks`, {
      params,
      headers: { Accept: "application/hal+json" },
      paramsSerializer: (params) =>
        QueryString.stringify(params, { arrayFormat: "repeat" }),
      transformResponse: [
        (data: string) => PaginatedResponse.fromResponse(JSON.parse(data)),
      ],
    });
  },
  task(taskId: string): Promise<AxiosResponse<Document>> {
    return taskReadApiClient().get(`/tasks/${taskId}`, {
      transformResponse: [
        (data: string) => Document.fromDocument(JSON.parse(data)),
      ],
    });
  },
  getAttachment(taskId: string, attachmentId: string, mediaType: string) {
    return taskReadApiClient().get(
      `/tasks/${taskId}/attachments/${attachmentId}`,
      { responseType: "arraybuffer", headers: { Accept: mediaType } }
    );
  },
  previewAttachment(taskId: string, attachmentId: string, mediaType: string) {
    return taskReadApiClient().get(
      `/tasks/${taskId}/attachments/${attachmentId}/view`,
      { responseType: "arraybuffer", headers: { Accept: mediaType } }
    );
  },
};
