

















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Task } from "@/components/tasks/Task";
import SingleTask from "@/components/tasks/SingleTask.vue";
import Loader from "@/components/Loader.vue";
import { TaskCategory, taskFilter } from "@/models/task";
import { Role } from "@/models/role";
import { namespace } from "vuex-class";
import { taskReadApi } from "@/api/portal/task-read-api";

const user = namespace("user");
@Component({
  components: { SingleTask, Loader },
})
export default class TaskList extends Vue {
  @Prop()
  private taskCategory!: TaskCategory;

  @Prop()
  private role!: Role;

  @Prop()
  private noTaskMessage!: string;

  @Prop()
  private testData!: string;

  @Prop()
  private selectedAccountId: string | undefined;

  @user.State("userId")
  private userId!: string;

  private dataLoading: boolean = true;

  private todos: Task[] = [];

  @Emit()
  private countUpdated(n: number | undefined) {
    return n;
  }

  mounted() {
    this.dataLoading = true;
    const { documentStatus, scope } = taskFilter(this.taskCategory, this.role);
    taskReadApi
      .tasks({
        status: documentStatus,
        scope: scope,
        sort: "endDate,asc",
        pageSize: 5,
      })
      .then((response) => {
        this.countUpdated(response.data.total);
        return response.data._embedded?.tasks ?? [];
      })
      .then((documents) =>
        documents.map(
          (document) =>
            ({
              id: document.id,
              name: document.title,
              account: document.account.name,
              description: "",
              endDate: document.endDate,
              state: this.$t("models.document_status." + document.state),
            } as Task)
        )
      )
      .then((tasks) => {
        this.todos = tasks;
      })
      .catch((e) => {
        this.todos = [];
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }
}
