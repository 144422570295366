import { AccountType } from "@/enums/AccountType";
import { Account } from "@/interfaces/Account";
import { format, parse } from "date-fns";
import { nl } from "date-fns/locale";
import { filter } from "lodash";
import { Participant } from "@/models/participant";
import { ParticipantType } from "@/enums/ParticipantType";
import { CustomerSigningAction } from "@/enums/CustomerSigningAction";
import { NexusRecipient } from "@/enums/NexusRecipient";

export const documentStatuses = [
  "INITIAL",
  "SBR_SIGNING_REQUIRED",
  "IN_DRAFT",
  "DRAFT_COMPLETE",
  "READY_FOR_SENDING",
  "SENT",
  "OWNER_SIGNED",
  "SIGNING_COMPLETED",
  "DECLINED",
  "ADOPTION_REQUIRED",
  "ADOPTION_ACCEPTED",
  "ADOPTION_REJECTED",
  "SIGNABLE_DOCUMENTS_CREATED",
  "SIGNABLE_DOCUMENTS_CREATEPDF_ERR",
  "SBR_SENT",
  "SBR_SEND_ERROR",
  "SBR_COMPLETE",
  "ARCHIVING",
  "PROCESSED",
] as const;

export type DocumentStatus = typeof documentStatuses[number];

export enum FlowName {
  MFILES_SIGNING = "mfiles_signing",
  PDF_EMPLOYEE_CLIENT_SIGNING = "pdf_employee_client_signing",
}

export const documentPeriods = [
  "CALENDAR_YEAR_PERIOD",
  "CALENDAR_YEAR",
  "FINANCIAL_YEAR",
  "MONTH",
  "BIMONTHLY",
  "QUARTER",
  "QUARTER_FINANCIAL_YEAR",
] as const;

export type DocumentPeriod = typeof documentPeriods[number];

export enum DocumentScope {
  ALL = "ALL",
  SELF = "SELF",
}

export const documentTypes = [
  "advies",
  "offerte",
  "opdrachtbevestiging",
  "overeenkomst-overig",
  "lor",
  "concept-jaarrekening",
  "notulen",
  "controleverklaring",
  "verklaring-pensioen-en-stamrecht",
  "controleverklaringen",
  "bezwaarschrift",
  "dividendbelasting",
  "brief",
  "akkoord-mjl",
  "onderzoeksrapport-prognose",
  "vennootschapsbelasting",
  "verwerkersovereenkomst",
  "beoordelingsverklaring",
  "samenstellingsverklaring",
  "inkomstenbelasting",
  "kredietrapportage-beperkt",
  "kredietrapportage-middelgroot",
  "kredietrapportage-persoon",
  "omzetbelasting",
  "omzetbelasting-suppletie",
  "icp",
  "jaarrekening-micro",
  "jaarrekening-klein",
  "jaarrekening-middelgroot",
  "jaarrekening-groot",
  "inrichtingsjaarrekening-micro",
  "inrichtingsjaarrekening-klein",
  "inrichtingsjaarrekening-middelgroot",
  "inrichtingsjaarrekening-groot",
] as const;

export type DocumentType = typeof documentTypes[number];

export const documentCategories = [
  "documents_other",
  "documents_financial",
  "documents_fiscal",
  "documents_hr",
] as const;

export type DocumentCategory = typeof documentCategories[number];

export const BimonthsMap = new Map<string, string>([
  ["01", "jan-feb "],
  ["02", "feb-mrt "],
  ["03", "mrt-apr "],
  ["04", "apr-mei "],
  ["05", "mei-jun "],
  ["06", "jun-jul "],
  ["07", "jul-aug "],
  ["08", "aug-sep "],
  ["09", "sep-okt "],
  ["10", "okt-nov "],
  ["11", "nov-dec "],
  ["12", "dec-jan "],
]);

export interface SigningInfo {
  packageId: string;
  customerSigningAction: CustomerSigningAction;
}

export interface Organization {
  id: string;
  name: string;
}

export const departments = [
  "accountants_en_advies",
  "beheer",
  "bedrijfsadviseurs",
  "controle",
  "corporate_finance",
  "subsidieadviseurs",
] as const;
export type Department = typeof departments[number];

export interface ReportingPeriod {
  type: string;
  value: string;
}

export interface Attachment {
  attachmentType: string;
  id: string;
  documentId: string;
  fileName: string;
  contentType: string;
  size: number;
}

export interface IPaginatedResponse<T> {
  _embedded: T | null;
  _links: Links | null;
  total: number;
}

export interface ITasks {
  tasks: IDocument[] | undefined;
}

export interface IDocument {
  id: string;
  title: string;
  state: DocumentStatus;
  createdBy: string;
  documentType: DocumentType | null;
  period: ReportingPeriod;
  account: Account;
  organization: Organization;
  participants: Participant[];
  attachments: Attachment[];
  created: string;
  endDate: string;
  signingInfo: SigningInfo | null;
  flowName: FlowName | null;
}

export interface Links {
  first?: Link;
  previous: Link;
  self: Link;
  next: Link;
  last?: Link;
  page?: Link[];
}

export interface Link {
  href: string;
  page?: number;
}

export class PaginatedResponse<T> implements IPaginatedResponse<T> {
  _embedded: T | null = null;
  _links: Links | null = null;
  total: number = 0;

  static fromResponse(
    paginatedResponse: IPaginatedResponse<ITasks>
  ): IPaginatedResponse<ITasks> {
    const tasks: IDocument[] | undefined =
      paginatedResponse._embedded?.tasks?.map((entry: IDocument) =>
        Document.fromDocument(entry)
      );
    const paginatedR = new PaginatedResponse<ITasks>();
    paginatedR._embedded = {
      tasks: tasks,
    };
    paginatedR._links = paginatedResponse._links;
    paginatedR.total = paginatedResponse.total;
    return paginatedR;
  }
}

export class Document implements IDocument {
  account: Account = {
    name: "",
    id: "",
    accountNumber: "",
    type: AccountType.COMPANY,
  };
  organization: { name: string; id: string } = { name: "", id: "" };
  attachments: Attachment[] = [];
  period: ReportingPeriod = { type: "", value: "" };
  createdBy: string = "";
  documentType: DocumentType | null = null;
  endDate: string = "";
  created: string = "";
  id: string = "";
  participants: Participant[] = [];
  state: DocumentStatus = "INITIAL";
  title: string = "";
  archived: boolean = false;
  nexusRecipient: NexusRecipient | "" = "";
  vatFiscalEntityDivision: string = "";
  signingInfo: SigningInfo | null = null;
  flowName: FlowName | null = null;

  constructor(document?: Partial<IDocument>) {
    Object.assign(this, document);
  }

  static fromDocument(document: IDocument): Document {
    return new Document(document);
  }

  // casl model name
  static get modelName() {
    return "Document";
  }

  static get modelTranslation() {
    return this.modelName.toLowerCase();
  }

  public getUserReadablePeriod(): string {
    let date;
    const formatOptions = { locale: nl };
    const biMonthMatcher = /(\d{4})-2M(\d{2})/;
    const calYearPeriodMatcher = /(\d{4})-P(\d{2})/;
    switch (this.period.type) {
      case "CALENDAR_YEAR_PERIOD":
        const yearPeriodMatcher = calYearPeriodMatcher.exec(this.period.value);
        if (yearPeriodMatcher === null) return this.period.value;
        const period = parseInt(yearPeriodMatcher[2]);
        return period == 1
          ? yearPeriodMatcher[1]
          : yearPeriodMatcher[1] + " (" + period + ")";
      case "CALENDAR_YEAR": //for example '2020'
        return this.period.value;
      case "QUARTER": // for example: '2020-Q1'
        date = parse(this.period.value, "yyyy-'Q'Q", new Date());
        return format(date, "QQQQ yyyy", formatOptions);
      case "QUARTER_FINANCIAL_YEAR":
        const matcher = /^([0-9]{4})-Q([1-4])-M([0-9]{1,2})$/gi.exec(
          this.period.value
        );
        if (matcher === null) {
          return this.period.value;
        } else {
          return (
            matcher[1] + " Q" + matcher[2] + " (Startmaand " + matcher[3] + ")"
          );
        }
      case "MONTH": // for example '2020-M01'
        date = parse(this.period.value, "yyyy-'M'MM", new Date());
        return format(date, "MMMM yyyy", formatOptions);
      case "BIMONTHLY": // for example '2020-M01'
        const valueMatcher = biMonthMatcher.exec(this.period.value);
        if (valueMatcher === null) {
          return this.period.value;
        } else {
          return (
            (BimonthsMap.get(valueMatcher[2]) || "unknown") + valueMatcher[1]
          );
        }
      case "FINANCIAL_YEAR": //for example '2020-2021-M06'
        let [yearFrom, yearTill, month]: any = this.period.value.split("-");
        yearFrom = parse(yearFrom, "yyyy", new Date());
        yearTill = parse(yearTill, "yyyy", new Date());
        month = parse(month, "'M'MM", new Date());
        return (
          format(month, "MMMM", formatOptions) +
          " " +
          format(yearFrom, "yyyy", formatOptions) +
          "-" +
          format(yearTill, "yyyy", formatOptions)
        );
      case "CUSTOM": //for example '2020-01-01-2020-01-02'
        const startDate = parse(
          this.period.value.substr(0, 10),
          "yyyy-MM-dd",
          new Date()
        );
        const endDate = parse(
          this.period.value.substr(11),
          "yyyy-MM-dd",
          new Date()
        );
        return `${format(startDate, "dd-MM-yyyy", formatOptions)} - ${format(
          endDate,
          "dd-MM-yyyy",
          formatOptions
        )}`;
      default:
        return this.period.value;
    }
  }

  get employeeSigner(): Participant | null {
    return (
      filter(this.participants, {
        role: "signer",
        participantType: ParticipantType.EMPLOYEE,
      })[0] || null
    );
  }
}
