import Home from "@/views/Home.vue";
import { RouteConfig } from "vue-router";
import Landing from "@/views/Landing.vue";
import { TaskCategory } from "@/models/task";

const importView = (name: string) => {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/${name}.vue`);
};

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "home",
    component: Landing,
    meta: {
      public: true,
    },
  },
  {
    path: "/about",
    name: "about",
    component: importView("About"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Home,
  },
  {
    path: "/gebruikersbeheer",
    name: "usermanagement",
    component: importView("usermanagement/UserManagement"),
    props: (route) => ({
      selectedAccountId: route.query?.selectedAccountId,
    }),
  },
  {
    path: "/gebruikersbeheer/:accountId/profiles/:profileId",
    name: "usermanagement-detail-employees",
    component: importView("usermanagement/employees/UserDetail"),
    props: true,
  },
  {
    path: "/gebruikersbeheer/nieuw",
    name: "usermanagement-link",
    component: importView("usermanagement/UserManagementLinkUser"),
    props: (route) => ({ userId: route.query?.userId }),
  },
  {
    path: "/gebruikersbeheer/nieuw/gebruiker",
    name: "usermanagement-new-user",
    component: importView("usermanagement/UserManagementCreate"),
  },
  {
    path: "/gebruikersbeheer/:contactId",
    name: "usermanagement-detail-edit",
    component: importView("usermanagement/UserManagementDetail"),
    props: (route) => ({
      contactId: route.params.contactId,
      edit: true,
      selectedAccountId: route.query?.selectedAccountId,
    }),
  },
  {
    path: "/gebruikersbeheer/nieuw/gebruiker/rollen",
    name: "usermanagement-detail-new",
    component: importView("usermanagement/UserManagementDetail"),
    props: (route) => ({ contactId: route.query.contactId, edit: false }),
  },
  {
    path: "/taken",
    name: "documents",
    component: importView("documents/Documents"),
    props: (route) => ({
      category: route.query?.category || "my",
      selectedAccountId: route.query?.selectedAccountId,
      selectedBranchName: route.query?.selectedBranchName,
    }),
  },
  {
    path: "/taken/nieuw",
    name: "documents-new",
    component: importView("tasks/TasksCreate"),
    props: (route) =>
      <{ category: TaskCategory }>{ category: route.query?.category || "my" },
  },
  {
    path: "/taken/:documentId",
    name: "documents-show",
    component: importView("documents/DocumentsShow"),
    props: (route) => ({
      category: route.query?.category || "my",
      documentId: route.params.documentId,
      selectedAccountId: route.query?.selectedAccountId,
      selectedBranchName: route.query?.selectedBranchName,
    }),
  },
  {
    path: "/taken/:documentId/sbr-signing",
    name: "documents-sbr-signing",
    component: importView("documents/DocumentsSbrSigningCallback"),
    props: (route) => ({
      documentId: route.params.documentId,
      taskUrl: route.query?.taskUrl,
    }),
  },
  {
    path: "/dossier",
    name: "dossier",
    component: importView("dossier/Dossier"),
    props: (route) => ({
      selectedAccountIds: route.query?.selectedAccountIds || [],
      searchFilter: route.query?.searchFilter || "",
    }),
  },
  {
    path: "/dossier/bestand-toevoegen",
    name: "file-upload",
    component: importView("dossier/DossierUpload"),
  },
  {
    path: "/dossier/:accountId/:vault/:documentId",
    name: "dossier-document",
    component: importView("dossier/DocumentDetails"),
    props: (route) => ({
      selectedAccountIds: route.query?.selectedAccountIds,
      accountId: route.params.accountId,
      documentId: route.params.documentId,
      vault: route.params.vault,
    }),
  },
  {
    path: "/kennisbank",
    name: "knowledge-base",
    component: importView("knowledgeBase/KnowledgeBase"),
  },
  {
    path: "/contact",
    name: "contact",
    component: importView("Contact"),
  },
  {
    path: "*",
    name: "NotFound",
    component: importView("NotFound"),
  },
];

export default routes;
